import { Typography } from "@material-ui/core";
import React, { FC, ReactNode, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectIsFormDisplayForValidationManagement } from "../../redux/selectors";

type Props = {
  value: ReactNode;
};

export const FormFieldInitValue: FC<Props> = ({ value }) => {
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );

  const formattedValue = useMemo(() => {
    if (typeof value === "boolean") {
      return value ? "Yes" : "No";
    }
    return value;
  }, [value]);

  if (isFormDisplayForValidationManagement) {
    return (
      <Typography variant="subtitle2">
        Initial: “{<Typography display="inline">{formattedValue || "No value"}</Typography>}“
      </Typography>
    );
  }
  return null;
};
